
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.rc-checkbox-ui{
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0;
      
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.10em solid $border-color;
        border-radius: 0.15em;
        transform: translateY(0.075em);
      
        display: grid;
        place-content: center;
        cursor: pointer;
        &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            /* Windows High Contrast Mode */
            background-color: $primary;
        }
        &:checked {
            border-color: $primary;
            background-color: $primary;
            &::before {
                transform: scale(1);
                background-color: $white;
            }
            &:disabled {
                background-color: $gray-light;
                border-color: $gray-light;
                cursor: not-allowed !important;
            }
        }
        &:disabled {
            background-color: $gray-pastel;
            cursor: not-allowed !important;
        }
        
        &:focus {
           //outline: max(.05em, .15em) solid $gray-light;
            //outline-offset: max(.05em, 0.15em);
        }
        
      }
      label {
        padding: 0 0.5rem;
        font-size: 14px;
        line-height: 18px;
        max-width: calc( 100% - 25px);
        font-weight: 400;
        color: $gray-light;
    }
      span {
      }
}

