@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";


.ct-close {
    color: $danger;
    background: $white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .25s ease-in-out;
    cursor: pointer;

    svg {
        width: 12px
    }

    &:hover {
        color: $white;
        background: $danger;
    }

    &.gray {
        background: $gray-pastel;
        color: $danger;
        &:hover{
            box-shadow: 0 0 7px rgba(0,0,0,0.2);
        }
    }

    &.md {
        width: 30px;
        height: 30px;

        svg {
            width: 16px
        }
    }

    &.lg {
        width: 38px;
        height: 38px;

        svg {
            width: 18px
        }
    }

}