@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/_variables.scss";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.rc-window-ui {
    position: fixed;
    z-index: 12;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
    transition: $transition-fast;
    visibility: hidden;
    opacity: 0;

    .rc-window-ui__container {
        transform: scale($scale-initial);
    }

    &.show {
        visibility: visible;
        opacity: 1;

        .rc-window-ui__container {
            transform: scale($scale-final);
        }
    }

    &.minimized {
        width: fit-content;
        height: 35px;
        right: 0;
        top: calc(100% - 35px);
        margin-right: 10px;

        // &.minimized {
        //     align-items: flex-end;
        //     justify-content: flex-end;
        .rc-window-ui__container {
            height: auto;
            width: 250px;
            cursor: pointer;
            user-select: none;
            bottom: -5px;

            .rc-window-ui__body {
                visibility: hidden;
                opacity: 0;
            }

            .rc-window-ui__header {
                justify-content: center;
                background-color: $gray-light;

                h3 {
                    color: $white;
                }

                ul {
                    display: none;
                    visibility: hidden;
                    opacity: 0;
                }

                &__save {
                    display: none;
                }
            }

            .rc-window-ui__form,
            .rc-window-ui__body,
            .rc-window-ui__footer {
                visibility: hidden;
                opacity: 0;
                height: 0;
                padding: 0;
            }

        }
    }

    &__container {
        background: $white;
        width: 800px;
        height: 700px;
        border-radius: 3px;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;
        transition: $transition-fast;
        position: relative;
        z-index: 13;

        &.expanded {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
        }

    }

    &__header {
        background-color: $gray-pastel;
        border-bottom: solid 1px $border-color;
        padding: 8px;
        border-radius: 3px 3px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: $transition-fast;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            li {
                background: rgba($gray-light, .3);
                width: 18px;
                height: 18px;
                //border: solid 2px $border-color-light;
                border-radius: 50%;
                margin-right: 5px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    padding: 0;
                    width: 8px;
                    height: 8px;
                }

                &:last-child {
                    margin: 0;
                }

                &.tool-close {
                    background-color: rgba($danger, .4);
                    border-color: $danger-light;

                    svg path {
                        fill: $danger-dark;
                    }
                }

                &.toogle-tool-minimize {
                    background-color: rgba($warning, .4);
                    border-color: $warning-light;

                    svg path {
                        fill: $warning-dark;
                    }
                }

                &.toogle-tool-expand {
                    background-color: rgba($success, .4);
                    border-color: $success-light;

                    svg path {
                        fill: $success-dark;
                    }
                }
            }
        }

        h3 {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            padding: 0;
            color: $gray;
        }

        &__save {
            width: 64px;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            svg path {
                stroke: $primary;
            }
        }
    }

    &__form {
        display: flex;
        padding: 10px;
        border-bottom: solid 1px $border-color-light;

        .ui-select-box {
            flex-grow: 1;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__body {
        display: flex;
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        z-index: 0;

        .rc-map-search {
            position: absolute;
            left: 70px;
            top: 10px;
            z-index: 9999;
            right: 48px;
            justify-content: flex-end;
            display: flex;

            .rc-search-container {
                display: flex;
                align-items: center;
                width: 500px;
                background-color: rgba($white, .9);
                padding: 4.5px;
                border: solid 2px rgba(0, 0, 0, 0.2);
                border-radius: 5px; 

                .input-form-group .input-form input {
                    border: none;
                }
            }
        }

        &__map {
            width: 100%;
            height: 100%;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background: $gray-pastel;
        border-top: solid 1px $border-color-light;
        border-radius: 0 0 3px 3px;
    }
}