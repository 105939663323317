// ================================================================================================
// 	File Name: authentication.scss
// 	Description: Page content different authentication pages layouts SCSS.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

.autentication-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.autentication-modal {
  .password-strength{
    .header{
      display: flex;
      justify-content: space-between;
      align-items: 'center';
      margin-bottom: .5rem;
      h5 {
        font-size: $font-size-sm;
        color: $gray-900;
        margin-bottom: 0;
      }
      .strength {
        display: flex;
        align-items: center;
        width: 55%;
        .point {
          height: .35rem;
          width: calc(20% - .3rem);
          margin: 0 0 0 .3rem;
          border-radius: .5rem;
          background-color: $gray-100;
        }
      }
    }
    ul{
      padding: 0;
      margin: 0 0 0 0;
      li {
        list-style: none;
        padding: .2rem 0;
        font-size: $font-size-sm;
        color: $gray-300;
      }
  
    } 
    
  }
  .autentication-overlay{
    content: '';
    background-color: rgba($black, .5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  /*::before{
    content: '';
    background-color: rgba($black,.05);
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }*/
}

.login-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:before {
    content: '';
    position: fixed;
    opacity: .5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(126.95deg, #EC8686 0%, #8D8D8D 50.58%, $secondary 100%), linear-gradient(126.95deg, #FFFFFF 0%, $primary 49.48%, $secondary 100%), radial-gradient(100% 233.99% at 0% 100%, $primary 0%, #6100FF 100%), linear-gradient(307.27deg, #1DAC92 0.37%, #2800C6 100%), radial-gradient(100% 140% at 100% 0%, $primary 0%, $secondary 57.29%, $primary 100%);
  background-blend-mode: overlay, overlay, difference, difference, normal;
  }
}

.logo{
  width: 280px;
  max-width: 80%;
  height: auto;
}
// authentication pages background
.bg-authentication {
  background-color: $blank-bg-color;
  .auth-title,
  .vx-checkbox-con span,
  .divider .divider-text {
    color: $body-color;
  }
  .auth-footer {
    padding: 0rem 1.5rem 0.5rem;
    .footer-btn {
      .btn {
        padding: 0.87rem 1.2rem !important;
        margin: 1rem 1rem 1rem 0rem;
      }
    }
  }
}

.login-tabs-container {
  //min-height: 505px;
}

// Unlock btn spacing in sm

@media (max-width: 400px) {
  .unlock-btn {
    margin-top: 1rem;
  }
}
