// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.modal-window-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  &.top {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease;
  &.show {
    visibility: visible;
    opacity: 1;
  }
  .modal-window-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
  .modal-window {
    background-color: $white;
    border-radius: 3px;
    width: 450px;
    max-width: calc(100vw - 40px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease;
    position: relative;
    max-height: calc(100% - 40px);
    &.fullscreen {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
    .modal-window-header {
      border-bottom: solid 1px rgba($gray-100, 0.5);
      padding: 10px;
      h2 {
        font-size: 17px;
        margin: 0;
        font-weight: 400;
        color: $primary;
        text-align: center;
      }
    }
    .modal-window-body {
      padding: 10px;
      max-height: calc(100vh - 140px);
      overflow-x: auto;
    }
    .modal-window-footer {
      padding: 10px;
      border-top: solid 1px rgba($gray-100, 0.5);
      background-color: rgba($gray-100, 0.1);
    }
    .illustration {
      margin: 0 auto 25px auto;
      text-align: center;
    }
    &.top {
      margin-top: -25px;
      &.show {
        visibility: visible;
        opacity: 1;
        margin-top: 25px;
      }
    }
    &.center {
      align-items: center;
      margin: 15px 0;
      transform: scale(0.7);
      &.show {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
    &.bottom {
      align-items: flex-end;
      margin-bottom: -25px;
      &.show {
        visibility: visible;
        opacity: 1;
        margin-bottom: 25px;
      }
    }
    &.window-table {
      .body-card-content {
        display: flex;
        flex-direction: column;
      }
      .table-wrapper {
        //min-height: 300px;
        justify-content: space-between;
      }
    }
    &.window-md {
      width: 500px;
    }
    &.window-lg {
      width: 1000px;
    }
    &.window-lg-2 {
      width: 900px;
    }
    &.window-xl {
      width: 1400px;
    }
    h4 {
      color: $gray-dark;
      text-align: center;
      font-size: 16px;
    }
    p {
      color: $gray;
      font-size: 15px;
      // text-align: center;
    }
    .ct-close {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $danger;
      background: $white;
      width: 22px;
      height: 22px;
      border-radius: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.25s ease-in-out;
      svg {
        width: 12px;
      }
      &:hover {
        color: $white;
        background: $danger;
      }
    }
    &.success {
      .body-card-footer {
        background-color: $success-pastel;
        border-color: rgba($success-dark, 0.2);
      }
      .body-card-container {
        background-color: rgba($success-pastel, 0.2);
        border-color: rgba($success-dark, 0.2);
        //border-radius: 3px;
      }
      .body-card-content,
      .body-card-content p {
        color: $spanish-gray-dark;
      }
      h1 {
        color: $success-dark;
      }
    }
    &.primary {
      .body-card-footer {
        background-color: $primary-pastel;
        border-color: rgba($primary-dark, 0.2);
      }
      .body-card-container {
        background-color: rgba($primary-pastel, 0.2);
        border-color: rgba($primary-dark, 0.2);
        //border-radius: 3px;
      }
      .body-card-content,
      .body-card-content p {
        color: $spanish-gray-dark;
      }
      h1 {
        color: $primary-dark;
      }
    }
    &.warning {
      .body-card-footer {
        background-color: $warning-pastel;
        border-color: rgba($warning-dark, 0.2);
      }
      .body-card-container {
        background-color: rgba($warning-pastel, 0.2);
        border-color: rgba($warning-dark, 0.2);
        //border-radius: 3px;
      }
      .body-card-content,
      .body-card-content p {
        color: $spanish-gray-dark;
      }
      h1 {
        color: $warning-dark;
      }
    }

    &.danger {
      .body-card-footer {
        background-color: $danger-pastel;
        border-color: rgba($danger-dark, 0.2);
      }
      .body-card-container {
        background-color: rgba($danger-pastel, 0.6);
        border-color: rgba($danger-dark, 0.2);
        //border-radius: 3px;
      }
      .body-card-content,
      .body-card-content p {
        color: $spanish-gray-dark;
      }
      h1 {
        color: $danger-dark;
      }
    }

    &.info {
      .body-card-footer {
        background-color: $info-pastel;
        border-color: rgba($info-dark, 0.2);
      }
      .body-card-container {
        background-color: rgba($info-pastel, 0.6);
        border-color: rgba($info-dark, 0.2);
        //border-radius: 3px;
      }
      .body-card-content,
      .body-card-content p {
        color: $gray-dark;
      }
      h1 {
        color: $info-dark;
      }
    }
  }
}
