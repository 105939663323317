@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../assets/scss/core/variables/components-variables";

.ui-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: transparent;
        /* Not removed via appearance */
        margin: 0;
        font: inherit;
        color: $primary;
        width: 18px;
        height: 18px;
        border: 2px solid $border-color;
        border-radius: 50%;
        display: grid;
        place-content: center;
        &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            background: $primary;
        }
        &:checked {
            border: 2px solid $primary;
            &.radio-primary {
                border: 2px solid $primary;
                color: $primary;
                &::before {
                    background: $primary;
                }
            }
            &.radio-secondary {
                border: 2px solid $secondary;
                color: $secondary;
                &::before {
                    background: $secondary;
                }
            }
            &.radio-success {
                border: 2px solid $success;
                color: $success;
                &::before {
                    background: $success;
                }
            }
            &.radio-warning {
                border: 2px solid $warning;
                color: $warning;
                &::before {
                    background: $warning;
                }
            }
            &.radio-info {
                border: 2px solid $info;
                color: $info;
                &::before {
                    background: $info;
                }
            }
            &.radio-system {
                border: 2px solid $system;
                color: $system;
                &::before {
                    background: $system;
                }
            }
            &.radio-danger {
                border: 2px solid $danger;
                color: $danger;
                &::before {
                    background: $danger;
                }
            }
            &.radio-spanish-gray {
                border: 2px solid $spanish-gray;
                color: $spanish-gray;
                &::before {
                    background: $spanish-gray;
                }
            }

        }
        &:checked::before {
            transform: scale(1);
        }
    }
    label {
        flex: 1;
        cursor: pointer;
        margin-left: 5px;
        font-size: 13.5px;
        color: $gray-light;
        font-weight: 400;
    }
    &.warning {
        input[type="radio"] {
            border: 2px solid $warning-light;
        }
        label {
            color: $warning-light;
        }
    }
}
