@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";


.topbar {
    background: $white;
    display: flex;
    padding: 15px 0 10px 0;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9;
    .fullWidth {
        width: 100%;
        padding: 0 10px;
    }
    h2 {
        font-size: $base-font-size * 1.25;
        font-weight: 300;
        margin: 0;
        padding: 0 15px;
        color: $gray-900;
        height: calc( 100% - 20px);
        display: flex;
        align-items: center;
        border-left: solid 1px rgba($gray-100, .5);
    }
    h1 {
        font-size: $base-font-size * 1.50;
        font-weight: 400;
        margin: 0;
        color: $primary;
        display: flex;
        align-items: center;
        // white-space: nowrap;
    }
    @include media-breakpoint-down(sm) {
        .container > div {
            flex-direction: column;
            h1 {
                margin: 0;
                padding: 0;
            }

        }
    }
    &.auto-height {
        height: auto !important;
    }
    
    input {
        border-color: rgba($gray-100, .5);
    }
    .topbar-category-page {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom   : solid 1px rgba($gray-100, .5);
        background: #fbfcfc  ;
        h3 {
            flex-grow: 1;
            font-size: $base-font-size * 1.5;
            font-weight: 300;
            margin: 0;
            padding: 0 88px 0 15px      ;
            color: $primary;
            height: calc( 100% - 20px);
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: solid 1px rgba($gray-100, .5);
            
        }
    }

}