@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.password-strength{
    .header{
    display: flex;
    justify-content: space-between;
    align-items: 'center';
    margin-bottom: .5rem;
    h5 {
        font-size: $font-size-sm;
        color: $gray-900;
        margin-bottom: 0;
    }
    .strength {
        display: flex;
        align-items: center;
        width: 55%;
        .point {
        height: .35rem;
        width: calc(20% - .3rem);
        margin: 0 0 0 .3rem;
        border-radius: .5rem;
        background-color: $gray-100;
        }
    }
    }
    ul{
    padding: 0;
    margin: 0 0 0 0;
    li {
        list-style: none;
        padding: .2rem 0;
        font-size: $font-size-sm;
        color: $gray-300;
    }

    } 
    
}
.autentication-overlay{
    content: '';
    background-color: rgba($black, .5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
