// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.empty-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    
    .noting-state {
        text-align: center;
        margin: 1rem 3rem;
        h3 {
            font-size: $font-size-base * 1.25;
            color: $gray-light
        }
        p {
            font-size: $font-size-base;
            color: $gray-light;
        }
        img {
            max-width: 220px;
            max-height: 220px;
            margin-bottom: 3rem;
        }
    }
}