@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";

.ui-toggle-full-screen{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    background-color: $white;
    border: 2px solid #00000033;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}