@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";


.ui-scroll-bar {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    &.horizontal {
        overflow-x: auto;
        overflow-y: hidden;
    }

    &::-webkit-scrollbar {
        height: 10px;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $gray-100;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background: $gray-50;
    }

    .loading-scroll-bar {
        position: sticky;
        bottom: 0;
        left: 0;
        height: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: 140ms;
        &.show{
            visibility: visible;
            opacity: 1;
        }

        &__content {
            position: absolute;
            width: 100%;
            height: 30px;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            box-shadow: 0 0 10px rgba(black, 0.1);

            .fallback-spinner {
                display: flex;
                justify-content: center;
                align-items: center;

                .loading {
                    position: absolute;
                    margin-top: 0;
                    top: calc(50% - 12px);
                    left: calc(50% - 12px);
                    height: 24px;
                    width: 24px;

                    .effects {
                        border-width: 1px;
                    }
                }
            }
        }
    }
}