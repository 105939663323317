@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../../assets/scss/core/variables/components-variables";

//Variables
$chat-image-back-color: #F1F1F1;

.datetime-container {
    max-width: 315px;
    padding: 0 10px 10px 10px;
    margin-top: 5px;
    .calendar {
        /*border-bottom: solid 1px rgba($gray-light, .5);
        border-top: solid 1px rgba($gray-light, .5);*/
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 5px;
        user-select: none;
        .calendar-header {
            padding: 7px 0 10px 0;
            //text-transform: uppercase;
            color: $gray;
            font-weight: 600;
            //border-bottom: solid 1px rgba($gray-light, .5);
            .calendar-month {
                color: $primary;
            }
            .calendar-prev, .calendar-next {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: $primary-pastel;
                border-radius: 2px;
                color: $primary;
                span {
                    margin-top: -3px;
                }
                &.disabled {
                    cursor: not-allowed;
                    opacity: .4;
                }
            }
            .calendar-today {
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: $gray-pastel;
                border-radius: 2px;
                padding: 0 8px;
                margin: 0 2px;
                font-size: $font-size-sm * .9;
                text-transform: none;
            }
        }
        .week-column {
            text-align: center;
            //padding: 3px;
            flex-grow: 1;
            .week-name {
                font-weight: 700;
                //text-transform: uppercase;
                font-size: $font-size-sm * .9;
                color: $gray;
            }
            &.weekend {
                background: rgba($gray-light, .05);
                .week-days {
                    .week-day {
                        color: $gray-light;
                    }
                }
            }
            &.sunday {
                .week-name {
                    color: $danger;
                }
                .week-days {
                    .week-day {
                        color: $danger;
                    }
                }
            }

            .week-days {
                margin: 0 0px;
                height: 100%;
                .week-day {
                    padding: 2px;
                    width: 100%;
                    min-width: 32px;
                    padding: 7px 0;
                    // height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: $font-size-sm * .9;
                    color: $gray-light;
                    cursor: pointer;
                    transition: all 100ms ease-in-out;
                    line-height: 16px;
                    position: relative;
                    // border-radius: 2px;
                    .today-marker {
                        width: calc(100% - 2px);
                        height: 2px;
                        background: $primary;
                        position: absolute;
                        z-index: 99999999;
                        bottom: 1px;

                    }
                    &.today {
                        color: $primary;
                        font-weight: 500;
                    }
                    &.disabled {
                        cursor: default;
                        opacity: .4;
                    }
                    &.out-of-month {
                        opacity: .4;
                    }
    
                    
                    &:hover {
                        background: rgba($gray-light, 1);
                        color: $white;
                        border: none
                    }
                    &.selected {
                        background: $primary;
                        color: white;
                        font-weight: 600;
                        position: relative;
                        z-index: 9;
                        .today-marker {
                            background: $white;
                        }
                    }
                    &.interval {
                        background: $primary;
                        position: relative;
                        border-radius: inherit !important;
                        color: $white;
                        .today-marker {
                            background: $white;
                        }
                        &:before {
                            // content: '';
                            // position: absolute;
                            // background: $primary;
                            // width: 1px;
                            // height: 100%;
                            // left: 100%;
                            // z-index: 0;
                        }

                        // &:after {
                        //     content: '';
                        //     position: absolute;
                        //     background: $primary;
                        //     width: 16px;
                        //     height: 100%;
                        //     right: 100%;
                        //     z-index: 0;
                        // }
                        &.no-right {
                            &:before {
                                width: 8px;
                            }
                        }
                        &.no-left {
                            &:after {
                                width: 8px;
                            }
                        }
                    }
        

                }
            }
        }
    }
    .time-selector {
        display: inline-flex;
        border-radius: 3px;
        margin: 0 7px;
        position: relative;
        z-index: 9999;
        color: $gray-light;
        border: solid 1px $border-color-light;
        background-color: $gray-pastel;
        .time-divider {
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2px;
            background: $gray-pastel;
            &:before {
                content: ':';
                margin-top: -2px;
            }
        }
        .hour-selector, .minute-selector {
            position: relative;
            z-index: 9;
            input {
                color: $gray-light;
                border: none;
                background-color: $gray-pastel;
                color: $gray-light;
                text-align: center;
                font-size: $font-size-sm;
                font-weight: 600;
                width: 30px;
                height: 28px;
                padding: 3px 0;
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
                border-radius: 2px;

                outline: none;
                &:focus {
                    color: $primary;
                  }
                  position: relative;
                  z-index: 9;
      
            }
            .time-list-container {
                position: absolute;
                height: 193px;
                bottom: -34px;
                width: calc(100% + 2px);
                padding: 0 2px;
                left: -1px;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                transition: $transition-fast;
                &.show {
                    visibility: visible;
                    opacity: 1;
                }
                ul.time-list {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    left: 0;
                    z-index: 0;
                    transition: $transition-fast;
                    li {
                        border: solid 1px $border-color-light;
                        width: 100%;
                        background-color: $gray-pastel;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 2px;
                        border-radius: 2px;
                        font-size: $font-size-sm * .9;
                        font-weight: 600;
                        cursor: pointer;
                        transition: $transition-fast;
                        &:hover {
                            background-color: $primary;
                            color: $white;
                        }
                        &:last-child {
                            // border-bottom: solid 1px $border-color-light;
                        }
                        &.hidden {
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                    &.next {
                        bottom: calc(100% + 2px);
                    }
                    &.prev {
                        top: calc(100% + 3px);
                    }
                }
            }
            
        }
        .hour-selector input {
            border-left: solid 1px $border-color-light;
            left: -1px;
            padding-left: 2px;
        }

        .minute-selector input {
            border-right: solid 1px $border-color-light;
            right: -1px;
            padding-right: 2px;
        }
       
        
    }
    .calendar-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7px;
        .calendar-time-input {
            display: flex;
            flex-grow: 1;
            border: solid 1px rgba($gray-light, .5);
            align-items: center;
            justify-content: center;
            background: rgba($gray-light, .2);
            border-radius: 5px;
            margin: 0 5px;
            input {
                width: 30px;
                flex-grow: 1;border: none;
                text-align: center;
                background: transparent;
                font-size: 16px;
                color: $gray-900;
            }
            .calendar-increase, .calendar-decrease {
                width: 25px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba($gray-light, .5);
                cursor: pointer;
                transition: all 100ms ease-in-out;
                &:hover {
                    background-color: rgba($gray-light, 1);
                }
            }
        }
        .calendar-button {
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($gray-light, .5);
            border-radius: 5px;
            color: $white;
            transition: all 100ms ease-in-out;
            cursor: pointer;
            &:hover {
                transform: scale(1.2);
            }
            &.accept {
                background: $success;
            }
            &.cancel {
                background: $danger;
            }
        }
    }
    .calendar-error {
        text-align: center;
        margin: 4px 0 0 0;
        font-size: $font-size-sm * .9;
    }

    .calendar-range-date {
        font-size: $font-size-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-light;
        .range-separator {
            padding: 0 5px;
        }
    }
    
}