
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.rc-toggle-ui{
    display: flex;
    align-items: center;
    cursor: pointer;
    .rc-toggle-ui-button {
        width: 38px;
        height: 20px;
        background-color: #f0efef;
        border: solid 1px $border-color-light;
        border-radius: 10px;
        margin-right: 5px;
        position: relative;
        .rc-toggle-ui-bullet {
            width: 14px;
            height: 14px;
            background: #5e5f6066;
            border-radius: 7px;
            //border: solid 1px #5e5f6066;
            top: 2px;
            left: 2px;
            position: absolute;
            transition: $transition-fast;
        }
        &.on {
            background-color: $primary-pastel;
            border-color: rgba($primary-light, .3);
            .rc-toggle-ui-bullet {
                //border-color: $primary-dark;
                background-color: $primary;
                left: inherit;
                right: 2px;
            }
            
        }
    }
    span {
        font-size: 13.5px;
        color: $gray-light;
        font-weight: 400;
    }
}