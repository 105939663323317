@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.ui-division-title{
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: auto;
    min-height: 1px;
    // div{
    //     font-size: 14px;
    //     color: $gray;
    // }
    &.pure-line {
        background: $border-color-light;
    }
    
    span {
        // display: block;
        color: $gray-light;
        font-size: 13px;
        position: relative;
        font-weight: 500;
        &:after {
            content: "";
            position: absolute;
            background-color: $border-color-light;
            height: 1px;
            width: 50vw;
            top: 50%;
            left: calc(100% + 5px);
        }

        &:before {
            content: "";
            position: absolute;
            background-color: $border-color-light;
            height: 1px;
            width: 50vw;
            top: 50%;
            right: calc(100% + 5px);
        }
    }
}