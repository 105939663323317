// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";
@import "../../assets/scss/core/variables/break-points.scss";

.table-wrapper {
  width: 100%;
  border: 1px solid $border-color;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  background-color: $white;
  display: flex;
  flex-direction: column;

  &.full-height {
    height: 100%;
    //background: #F8F8F8;
    //background: repeating-linear-gradient(0, #fb3, #fb3 31px, #58a 31px, #58a 62px);
    // background-image: linear-gradient(0deg, $border-color 0.78%, #F8F8F8 0.78%, #F8F8F8 25%, $border-color 25%, $border-color 25.78%, #FFFFFF 25.78%, #FFFFFF 50%, $border-color 50%, $border-color 50.78%, #F8F8F8 50.78%, #F8F8F8 75%, $border-color 75%, $border-color 75.78%, #FFFFFF 75.78%, #FFFFFF 100%);
    // background-size: 128.00px 128.00px;
    // background-position: right 67px;

    .table-body-wrapper>table {
      border-bottom: solid 1px $border-color;
      position: relative;
      // tbody {
      //     tr:last-child {
      //         td {
      //             position: relative;
      //             &:before {
      //                 content: "";
      //                 position: absolute;
      //                 width: 1px;
      //                 height:313px;
      //                 top: 100%;
      //                 background: $border-color;
      //                 left: -1px;
      //             }
      //         }
      //     }
      // }
      // &:before {
      //     content: '';
      //     position: absolute;
      //     width: 100%;
      //     height: 100vh;
      //     background-color: #1e86f9;
      //     top: 100%;
      //     background-image: linear-gradient(0deg, #000000 0.78%, #F8F8F8 0.78%, #F8F8F8 25%, #000000 25%, #000000 25.78%, #FFFFFF 25.78%, #FFFFFF 50%, #000000 50%, #000000 50.78%, #F8F8F8 50.78%, #F8F8F8 75%, #000000 75%, #000000 75.78%, #FFFFFF 75.78%, #FFFFFF 100%);
      //     background-size: 128.00px 128.00px;
      // }
    }
  }

  .table-body-wrapper {
    max-height: calc(100% - 45px);
    height: 100%;
    width: 100%;
    overflow: auto;
    position: relative;

    .datatable-loading {
      position: absolute;
      background-color: $white;
      width: 60px;
      height: 60px;
      border-radius: 10px;
      box-shadow: $box-shadow;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: $transition-fast;

      &.show {
        position: sticky;
        margin-top: -30px;
        visibility: visible;
        opacity: 1;
      }
    }

    @include to($breakpoint-sm) {
      max-height: calc(100% - 85px);
    }

    &::-webkit-scrollbar {
      height: 10px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #dadada;
    }

    &::-webkit-scrollbar-track {
      background: #f3f3f3;
      border-top: 1px solid $border-color;
      margin-top: 39px;
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: inherit;

      thead {
        visibility: hidden;
        opacity: 0;
        transition: $transition-fast;
        height: 40px;
        position: sticky;
        top: 0;
        z-index: 1;

        &.visible {
          visibility: visible;
          opacity: 1;
        }

        &.administrator {
          tr {
            th {
              &:nth-last-child(1) {
                cursor: pointer;

                &:hover {
                  background: $gray-pastel;
                }

                &::after {
                  background-color: $white;
                  width: 0px;
                }

                border-right: none;

                @include to($breakpoint-md) {
                  border: none;
                  padding: 0;
                  width: 30px;
                  right: 0;
                }
              }

              &:nth-last-child(2) {
                @include to($breakpoint-md) {
                  border-right: none;
                }
              }
            }
          }
        }

        tr {
          th {
            font-weight: 500;
            color: $gray-light;
            font-size: 13px;
            transition: 0.3s;
            border-right: 1px solid $border-color;
            vertical-align: middle;
            padding: 0 10px;
            // white-space: nowrap;
            background-color: #fff;
            position: sticky;
            top: 0;
            z-index: 1;

            &::before {
              content: "";
              width: 100%;
              height: 1px;
              background-color: $border-color;
              position: absolute;
              left: 0;
              bottom: 0;
            }

            &::after {
              content: "";
              width: 1px;
              height: 100%;
              background-color: $border-color;
              position: absolute;
              right: -1px;
              bottom: 0;
            }

            .table-head {
              display: flex;
              align-items: center;
              position: relative;
              // overflow: hidden;

              .table-ordering {
                font-size: 11px;
                position: absolute;
                right: 0;

                &.active {
                  color: $primary-dark;
                }
              }
            }

            &.ordering {
              cursor: pointer;
              transition: all 140ms ease-in-out;

              &:hover {
                background: $gray-pastel;
              }
            }

            .table-archive {
              user-select: none;

              .table-filter-icon {
                display: none;
              }

              @include to($breakpoint-md) {
                .table-archive-toggle {
                  position: absolute;
                  background: $white;
                  border-left: 1px solid $border-color;
                  border-bottom: 1px solid $border-color;
                  border-right: 1px solid transparent;
                  top: 0;
                  height: 40px;
                  padding: 5px 14px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  visibility: hidden;
                  opacity: 0;
                  right: 30px;
                  transition: all 140ms ease-in-out;

                  &.show {
                    visibility: visible;
                    opacity: 1;
                  }
                }

                .table-filter-icon {
                  position: absolute;
                  right: 0;
                  top: 0;
                  background: $white;
                  color: $gray-light;
                  border-left: 1px solid $border-color;
                  border-bottom: 1px solid $border-color;
                  font-size: 13px;
                  transition: all 140ms ease-in-out;
                  height: 40px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  user-select: none;
                  cursor: pointer;

                  svg path {
                    fill: $gray;
                  }

                  &.archived-active {
                    svg path {
                      fill: $success-dark;
                    }
                  }
                }
              }
            }
          }
        }
      }

      tbody {
        max-height: calc(100% - 40px);
        height: 100%;
        flex-grow: 1;
        height: fit-content;
        transition: $transition-fast;

        &.administrator {
          tr {
            td {
              button {
                height: 25px;
              }

              &:nth-last-child(1) {
                border-right: none;

                @include to($breakpoint-md) {
                  display: block;
                  border: none;
                  padding: 0;
                  width: 30px;
                  position: sticky;
                  right: 0;
                }
              }

              &:nth-last-child(2) {
                @include to($breakpoint-md) {
                  border-right: none;
                }
              }
            }

            &:nth-last-child(1) {
              .controller {
                &__three-points {
                  border-bottom: none;
                }
              }
            }
          }
        }

        .rc-checkbox-ui {
          &.checkbox-primary input[type="checkbox"]:checked {
            border-color: #503af8;
            background-color: #009ACA;
          }
          &.checkbox-secondary input[type="checkbox"]:checked {
            border-color: #07e119;
            background-color: #07e119;
          }
      }
        tr.red-highlight td {
          background-color: rgba(255, 1, 1, 0.075);
          color: red !important;
          &:first-child {
            border-left: 1px solid red !important;
          }
          &:last-child {
            border-right: 1px solid red !important;
          }
          border-top: 1px solid red !important;
          border-bottom: 1px solid red !important;
        }
        tr.red-highlight {
          color: red !important;
        }

        tr {
          height: 30px;

          &:nth-last-child(1) {
            td {
              border-bottom: 0;
            }
          }

          &:hover {
            td {
              background: $primary-pastel;
            }
          }

          &:nth-child(even) {
            background-color: #fff;
          }

          &:nth-child(odd) {
            background-color: $gray-pastel;
          }

          &.border-none {
            border: none;
          }

          &.light {
            td {
              background: #d7f4fd;
            }
          }

          &.warning {
            td {
              background: $warning-light;
              color: $white;

              span {
                color: $white !important;
              }

              svg {
                color: $white;
              }
            }
          }

          &.danger {
            td {
              background: $danger-light;
              color: $white;

              span {
                color: $white !important;
              }

              svg {
                color: $white;
              }
            }
          }

          &.primary {
            td {
              background: $primary-light;
              color: $white;

              > span:not(span.ui-tag) {
                color: $white !important;
              }

              svg {
                color: $white;
              }
            }
          }

          &.success {
            td {
              background: $success-light;
              color: $white;

              > span:not(span.ui-tag) {
                color: $white !important;
              }

              svg {
                color: $white;
              }
            }
          }

          &.info {
            td {
              background: $info-light;
              color: $white;

              > span:not(span.ui-tag) {
                color: $white !important;
              }

              svg {
                color: $white;
              }
            }
          }

          td {
            border-right: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            vertical-align: middle;
            padding: 0 10px;
            white-space: nowrap;
            color: $gray-light;
            font-size: 13px;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // transition: all 140ms ease-in-out;

            input {
              &[type="checkbox"] {
                cursor: pointer;
              }
            }

            &.center {
              text-align: center;
            }

            .controller {
              height: 30px;
              cursor: pointer;

              &__three-points {
                position: absolute;
                z-index: 1;
                right: 0;
                height: 30px;
                width: 30px;
                display: none;
                justify-content: center;
                align-items: center;
                border-left: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                background-color: #fff;

                @include to($breakpoint-md) {
                  display: flex;
                }

                span {
                  position: absolute;
                  height: 2px;
                  width: 2px;
                  background-color: #0f0f0f;

                  &::after {
                    content: "";
                    position: absolute;
                    top: -5px;
                    left: 0;
                    height: 2px;
                    width: 2px;
                    background-color: #0f0f0f;
                  }

                  &::before {
                    content: "";
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    height: 2px;
                    width: 2px;
                    background-color: #0f0f0f;
                  }
                }
              }

              &__content {
                height: 30px;
                //width: 180px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: all 140ms ease-in-out;

                @include to($breakpoint-md) {
                  position: absolute;
                  background-color: #fff;
                  border-left: 1px solid $border-color;
                  border-bottom: 1px solid $border-color;
                  padding: 0 10px;
                  right: 30px;

                  &--opened {
                    visibility: visible;
                    opacity: 1;
                  }

                  &--closed {
                    visibility: hidden;
                    opacity: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.table-footer-wrapper {
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: sticky;
  left: 0;
  bottom: 0;
  border-top: solid 1px $border-color;

  @include to($breakpoint-sm) {
    flex-direction: column;

    .table-footer-pagination {
      margin-bottom: 5px;
    }
  }

  background: #f8f8f8;

  .table-footer-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination-counter {
      margin-right: 15px;
    }

    .navigation-button {
      height: 30px;
      padding: 0 10px;
      border-radius: 3px;
      background-color: rgba($gray, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 3px;
      color: $gray;
      font-size: $font-size-sm;
      border: none;
      min-width: 30px;

      svg {
        path {
          stroke: #1e86f9;
        }
      }

      &:hover {
        background-color: rgba($gray, 0.6);
        color: #fff;

        svg {
          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }
  }

  .select-views {
    position: relative;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    height: 30px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    padding: 0 10px 0 10px;
    margin-left: 0;
    background: $white;

    &:hover {
      .select-views__label {
        color: $primary;
      }
    }

    &__label {
      font-size: 14px;
      color: #797979;
      position: relative;
      padding-right: 10px;
      transition: 0.3s;
      min-width: 70px;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: 0px;
        top: calc(50% - 5px);
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid;
        transform: rotate(90deg);
      }
    }

    &__dropdown {
      position: absolute;
      min-width: 100%;
      right: 0;
      border-radius: 3px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background: #fff;
      overflow: hidden;
      visibility: hidden;
      bottom: 0;
      opacity: 0;
      transition: 0.3s;
      max-height: 250px;
      overflow: auto;

      &--active {
        visibility: visible;
        bottom: 35px;
        opacity: 1;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 5px 5px;
          color: #797979;
          font-size: 14px;
          transition: all 140ms ease-in-out;
          text-align: center;

          &:hover {
            background-color: $gray-pastel;
            color: $gray;
          }

          &.selected {
            background-color: rgba($primary, 0.1);
            color: $primary;
          }
        }
      }
    }
  }
}

.truncate-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
