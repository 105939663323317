@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";

.map-area {
    background: $primary-pastel;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    background-size: cover;
    z-index: 0;
    position: relative;
    border-bottom: none;
    .map{
        height: 100%;
        width: 100%;
    }
    .map-coordinates {
        position: absolute;
        z-index: 9999999;
        color: $primary;
        background: rgba(255,255,255, .8);
        padding: 5px;
        border-radius: 5px;
        top: 5px;
        right: 5px;
    }
    .ui-toggle-full-screen{
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 999;
    }
    .ui-contest{
        position: absolute;
        right: 10px;
        top: 50px;
        z-index: 999;
    }
    .loading-shapes-spinner{
        position: absolute;
        z-index: 9999999;
        color: $primary;
        background: rgba(255,255,255, .8);
        padding: 5px 10px;
        border-radius: 5px;
        left: 5px;
        bottom: 5px;
        display: flex;
        align-items: center;
        >div{
            margin-right: 5px;
        }
    }
}