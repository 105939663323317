// ================================================================================================
//   File Name: chat-application.scss
//   Description: SCC file for chat application page.
//   ----------------------------------------------------------------------------------------------
//   Item Name: Vuexy React Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

//Variables
$chat-image-back-color: #F1F1F1;
$shadow-key-umbra-opacity: 0.10 !default;
$shadow-key-penumbra-opacity: 0.1 !default;
$shadow-ambient-shadow-opacity: 0.05 !default;

.ui-cards-container {
    width: 100%;
    max-width: 1400px !important;
    margin: auto;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    display: grid;
    align-items: center;
    justify-items: center;

    @media (max-width: 420px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .ui-cards-item {
        width: 100%;
        display: grid;
        grid-template-columns: auto;
        border-radius: 5px;
        gap: 15px;
        min-height: 210px;
        align-items: center;
        padding: 25px;
        background: rgba($white, .8);
        background-size: 30% !important;
        user-select: none;
        box-shadow: $box-shadow;
        transition: $transition-fast;
        border-top: solid 4px $gray-pastel;

        .ui-card{
          align-self: baseline;
        }
        .ui-card-title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            h3 {
                font-size: 17px;
                color: $primary;
                flex-grow: 1;
                margin: 0;
            }
        }

        figure {
            display: flex;
            height: 50px;
            width: 50px;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            overflow: hidden;
            background: $gray-pastel;
            //border: solid 1px $border-color;
            border-radius: 3px;
            overflow: hidden;
            margin-right: 10px;

            img {
                max-height: 40px;
                min-width: 40px;
            }
        }

        p {
            font-size: 14px;
            color: $gray-300;
            margin: 0;
        }

        &:hover {
            box-shadow: $box-shadow-elevate;
            transform: scale(1.1);
            border-color: $primary;
        }

        &.active {
            border-color: $primary;

            .ui-card-title {
                h3 {
                    color: $primary;
                    font-weight: 600;
                }
            }
        }

        /*
        grid-template-rows: 200px auto 104px minmax(min-content,auto);
        row-gap: 8px;
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
                    0 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
                    0 4px 12px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity);
        transition: 200ms;
        border-radius: 3px;
        overflow: hidden;
        align-self: center;
        justify-self: center;*/
    }

}
