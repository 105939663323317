@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.ui-tab {
    &--full-width {
        ul {
            li {
                width: 100%;
            }
        }
    }

    .ui-tab-label {
        font-size: 10px;
        font-weight: 500;
        color: $gray-light;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        li {
            font-size: 11px;
            padding: 4px 6px;
            border: solid 1px $border-color;
            border-left: none;
            font-weight: 400;
            background-color: $gray-pastel;
            color: rgba($gray-light, .7);
            text-align: center;
            cursor: pointer;

            &:first-child {
                border-left: solid 1px $border-color;
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;
            }

            &.disabled {
                background: rgba($gray-light, .3);
                cursor: not-allowed;
            }
        }
    }

    &.ui-tab-success {
        ul li.selected {
            background-color: $success-pastel;
            color: $success;
        }
    }

    &.ui-tab-primary {
        ul li.selected {
            background-color: $primary-pastel;
            color: $primary;
        }
    }

    &.ui-tab-danger {
        ul li.selected {
            background-color: $danger-pastel;
            color: $danger;
        }
    }

    &.ui-tab-warning {
        ul li.selected {
            background-color: $warning-pastel;
            color: $warning;
        }
    }

    &.warning {
        .ui-tab-label {
            color: $warning;
        }

        li {
            border-color: $warning;
            background-color: $warning-pastel;
            color: $warning;
            cursor: pointer;

            &:first-child {
                border-left-color: $warning;
            }
        }
    }
}