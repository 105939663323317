
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.toggle-archived{
    cursor: pointer;
    display: flex;
    display: flex;
    align-items: center;
    transition: all 140ms ease-in;
   .archive-check {
       width: 17px;
       height: 17px;
       background: $gray-pastel;
       border: solid 1px $border-color;
       border-radius: 8px;
       margin-right: 5px;
       display: flex;
       align-items: center;
       justify-content: center;
       svg {
        width: 12px;
        height: 12px;
        opacity: 0;
     }
   }
   small {
       font-weight: 500;
   }
   
   &.active {
    .archive-check {
        background: $warning;
        border: solid 1px $warning;
        border-radius: 8px;
        margin-right: 5px;
        svg {
            opacity: 1;
            color: $white;
            path {
                stroke: 4;
            }
         }
    }
    small {
        color: $warning;
    }
   }
    
}