.filterTypeItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: revert;
  margin-left: 15px;
  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    label {
      cursor: pointer;
    }
  }
}
