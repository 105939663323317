// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Load variable overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

.app-master-menu {
    position: fixed;
    width: 100%;
    height: calc(100% - 50px);
    background: rgba($gray-pastel, 1);
    z-index: 100;
    left: 0;
    top: 50px;
    visibility: hidden;
    opacity: 0;
    transition: all 140ms ease-in-out;
    display: flex;
    flex-direction: column;
    .app-master-menu-search-items {
        transition: all 140ms ease;
        transform: scale(1.5);
        flex-grow: 1;
        display: flex;
        align-items: center;
        //justify-content: center;
        flex-direction: column;
        opacity: 0;
        padding: 40px 0 15px 0;
        //border-top: solid 1px $border-color;
        overflow: auto;
        &.menu-items-visible {
            transform: scale(1);
            opacity: 1;
            
        }
        .master-menu-group {
            padding: 30px 20px 40px 20px;
            border-bottom: solid 1px $border-color;
            max-width: calc( 100% - 30px);
            background-color: rgba($white, .5);
            border: solid 1px $border-color;
            border-radius: 10px;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 22px;
            font-weight: 400;
            margin: 0 0 25px 0;
            color: $primary-dark
        }
        .release-date {
            color: rgba($gray-light, .4);
        }

    }
    .app-master-menu-search-bar {
        padding: 20px;
        //background: rgba($gray-dark, .05);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-group {
            max-width: 550px;
        }
        
    }
    &.master-menu-is-open {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
}

.scale-animation {
    overflow: hidden;
    transition: all 140ms ease;
    &.animation-active {
        border-radius: 15px;
        transform: scale(.85);
        filter: blur(7px);
        box-shadow: 0 0 0 1px rgba(0,0,0, .1), 0 4px 11px rgba(0,0,0,.1);

    }
}

.master-menu-open {
    width: 30px;
    height: 24px;
    border-bottom: solid 2px $gray-light;
    position: relative;
    transition: $transition-fast;
    left: 20px;
    position: absolute;
    top: 18px;
    z-index: 9999;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        border-bottom: solid 2px $gray-light;
    }
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        top: 50%;
        border-bottom: solid 2px $gray-light;
    }
    &:hover {
        border-color: $primary;
        transform: scale(1.1);
        &::before ,  &::after {
            border-color: $primary;
        }
    }
    &.menu-is-open {
        opacity: 0;
    }
  }

.master-menu-open_old {
    width: 71px;
    height: 71px;
    position: absolute;
    top: 0;
    left: 0;
    //border-radius: 10px;;
    background-color: $white;
    color: $gray-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    border-right: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
    //box-shadow: 0 0 0 1px rgba(0,0,0, .1), 0 4px 11px rgba(0,0,0,.1);
    display: flex;
    flex-direction: column;
    transition: all 140ms ease;
    cursor: pointer;
    span {
        font-size: 12px;
        font-weight: 500;
        color: $gray-light;
    }
    svg {
        width: 32px;
        path {
            fill: $primary;
        }
    }

    &.menu-is-open {
        width: 71px;
       // background: $primary;
        box-shadow: 0 0 0 1px rgba(0,0,0, .1), 0 4px 11px rgba(0,0,0,.1);
        //'top: 10px;
        //left: 10px;
        //border-radius: 10px;
        border-color: transparent;
        span {
            color: $danger;
        }
        svg {
            path {
                fill: $danger;
            }
        }
    }
}