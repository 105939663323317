
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.rc-tooltip-ui {
    position: absolute;
    right: calc(100%);
    background-color: $gray;
    color: $white;
    font-size: 12px;
    padding: 3px 12px;
    border-radius: 12px;
    top: 50%;
    margin-top: -12px;
    user-select: none;
    opacity: 0;
    visibility: hidden;
    transition: $transition-fast;
    z-index: 1;
    &.show {
        visibility: visible;
        opacity: 1;
        right: calc(100% + 5px);
    }
    &.down {
        top: 100%;
        margin-top: 5px;
        right: 0;
    }
    &.up {
        top: -100%;
        margin-top: -5px;
        right: 0;
        margin-right: 0!important
    }
}