@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";

.container {
  max-width: 1500px !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999 !important;
}
.MuiDialogContent-root {
  color: #717B85 !important;
}
.MuiModal-root {
  .MuiButtonBase-root {
    color: $gray;
    background: $white;
    &:hover {
      background: #efefef;
    }
  }
}
.MuiButtonBase-root .MuiIconButton-root {
  color: $danger;
  background: $white;
}
.MuiDialogContent-root .RootContent > div {
  z-index: 10001;
}

.MuiDialogActions-root {
  border-top: 1px solid #efefef;
  justify-content: space-between !important;
  background-color: #f8f8f8;
  padding: 1rem;
  .MuiButtonBase-root > div {
    display: flex;
    align-items: center;
    font-size: 13px !important;
    height: 35px;
    svg {
      font-size: 13px !important;
      margin-right: 10px;
    }
  }
}
