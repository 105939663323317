@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";

.ui-label{
    font-size: 13px;
    font-weight: 500;
    color: $gray-light;
}