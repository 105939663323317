@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";
@import "../../assets/scss/core/variables/break-points.scss";

.ui-accordion {
  width: 100%;

  &__icon {
    margin-right: 7px;
  }

  &__label {
    width: 100%;
    border: 1px solid $border-color-light;
    background: $gray-pastel;
    border-radius: 3px 3px 0 0;
    display: flex;
    align-items: center;
    padding: 7px;
    font-size: $font-size-sm;
    color: $gray-light;
    user-select: none;
    font-weight: 500;
    cursor: pointer;
  }

  &__content {
    overflow: hidden;
    max-height: 0;
    transition: 0.3s;
    margin-bottom: 5px;
    width: 100%;
    &__body {
      border-top: none;
      padding: 5px;
      border-radius: 0 0 3px 3px;
    }

    &.show {
      border: solid 1px $border-color-light;
      max-height: inherit;
      padding: 7px;
      border-radius: 3px;
    }
  }
}
