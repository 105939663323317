@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

/*FORMS*/
.input-form-group {
    //margin: .5rem 0;
    position: relative;

    .input-form {
        position: relative;
        box-sizing: border-box;
        input,
        textarea {
            padding: 6px 9px;
            border: solid 1px $border-color;
            width: 100%;
            border-radius: 3px;
            transition: all 140ms ease-in-out;
            color: $gray-light;
            background: $white;
            height: 35px;

            &::-webkit-calendar-picker-indicator {
                background: none;
                display: none;
            }

            &.sm {
                height: 30px;
                padding: 7px 8px;
                font-size: 13px;
            }
            &:focus { /* You can also use .element.text:focus here */
                border: solid 2px $primary;
                outline: 0;
            }
        }

        input[type=time],
        input[type=date] {
            font-size: 12px;

            &.sm {
                height: 30px;
                padding: 9px 12px 5px 12px;
                font-size: 13px;
            }
        }

        textarea~label {
            align-items: flex-start;
            padding-top: 5px;
        }

        label {
            position: absolute;
            z-index: 2999;
            top: 1px;
            margin-left: 9px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            font-size: 12px;
            color: $gray-light;
            transition: all 140ms ease-in-out;
            max-width: calc(100% - 10px);
            user-select: none;
        }

        .input-field-icon {
            position: absolute;
            top: 0;
            width: 35px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.has-icon {
            &.left {
                input {
                    padding-left: 30px;
                }

                label {
                    margin-left: 35px;
                    max-width: calc(100% - 45px);
                }
            }

            &.right {
                input {
                    padding-right: 30px;
                }

            }
        }


    }

    &.input-disabled {
        .input-form {
            input, textarea {
                background: $gray-pastel;
                cursor: not-allowed;
            }

            label {
                cursor: not-allowed;
            }
        }
    }

    .fields-legends {
        display: flex;
        align-items: center;

        .fields-legends-descriptions {
            flex-grow: 1;
            display: flex;
            small:after {
                content: ', ',
            }
            small:last-child:after {
                content: '';
            }
        }

        .fields-legends-counter {
            margin-top: 2px;
            font-size: 11px;
            color: $gray-light;
        }
    }
    .input-actions {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        margin-top: -11px;
        > div, > span {
            margin-right: 5px;
        }
    }

    .input-form-hint {
        z-index: 9;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        transition: all 140ms ease-in-out;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $warning;
        background: $warning-pastel;
        border-radius: 11px;
        top: 8px;
        right: 5px;
        z-index: 3999;

        .input-hint-text {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            color: $gray-light;
            right: -5px;
            bottom: 100%;
            width: 400px;
            background: $white;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 4px 11px rgba(0, 0, 0, .15);
            padding: 9px;
            transition: all 140ms ease-in-out;
            border-radius: 3px;
        }

        &:hover {
            background: $warning;
            color: $white;
            cursor: pointer;

            .input-hint-text {
                visibility: visible;
                opacity: 1;
                bottom: calc(100% + 15px);
            }

        }

    }
    .show-hide-characters {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($gray-light, .5);
        cursor: pointer;
        position: relative;
        transition: $transition-fast;
        &.active {
            color: $primary
        }
    }
    .input-form-clear {
        top: calc(50% - 11px);
        right: 5px;

        &.has-hint {
            right: 35px;
        }
    }
    // .input-border {
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     border: solid 2px $primary;
    //     top: 0;
    //     border-radius: 3px;
    //     opacity: 0;
    //     visibility: hidden;
    //     transition: $transition-fast;
    //     z-index: 0;
    // }

    // input:focus~.input-border,
    // textarea:focus~.input-border {
    //     opacity: 1;
    //     visibility: visible;
    // }

    input:focus~label.animated,
    textarea:focus~label.animated {
        color: $primary;
        height: auto;
        //background-color: $white;
        padding: 0 4px;
        font-size: 10px;
        top: -5px;
        margin-left: 5px;
        z-index: 999;
        margin-left: 5px !important;

        &:after {
            content: '';
            width: 100%;
            height: 50%;
            left: 0;
            top: calc(50% - 4px);
            background: $white;
            z-index: -1;
            position: absolute;
        }
    }

    input:focus~label.hide,
    textarea:focus~label.hide {
        opacity: 0;
        visibility: hidden;
    }

    input:focus~div,
    textarea:focus~div {
        svg {
            color: $primary;
        }
    }

    input:not(:placeholder-shown)~label.animated,
    textarea:not(:placeholder-shown)~label.animated,
    label.placeholder-top {
        height: auto;
        //background-color: $white;
        padding: 0 4px;
        font-size: 10px;
        top: -5px;
        margin-left: 5px !important;

        &:after {
            content: '';
            width: 100%;
            height: 50%;
            left: 0;
            top: calc(50% - 4px);
            background: $white;
            z-index: -1;
            position: absolute;
        }
    }

    input:not(:placeholder-shown)~label.hide,
    textarea:not(:placeholder-shown)~label.hide {
        opacity: 0;
        visibility: hidden;
    }

    &.is-invalid {
        &.danger {

            input,
            textarea {
                border-color: $danger-light  !important;
                background: $danger-pastel;
            }

            label {
                color: $danger-light;
            }

            input:focus+label,
            textarea:focus+label {
                color: $danger-light;
            }

            ~.input-field-chevron {
                color: $danger-light;

                &::before {
                    background-color: $danger-light  !important;
                }
            }
        }

    }

    &.is-invalid {
        &.warning {

            input,
            textarea {
                border-color: $warning-light  !important;
                background: $warning-pastel;
            }

            label {
                color: $warning-light;
            }

            input:focus+label,
            textarea:focus+label {
                color: $warning-light;
            }

            ~.input-field-chevron {
                color: $warning-light;

                &::before {
                    background-color: $warning-light  !important;
                }
            }
        }

    }

    &.user-field-warning {

        input,
        textarea {
            border-color: $warning  !important;
            background: $warning-pastel;
        }

        label {
            color: $warning-dark;
        }

        input:focus+label,
        textarea:focus+label {
            color: $warning-light;
        }

        ~.input-field-chevron {
            color: $warning-light;

            &::before {
                background-color: $warning-light  !important;
            }
        }
    }

    small {
        font-size: 11px;
        margin-top: 2px;
        margin-bottom: -2px;
        margin-left: 1px;
        line-height: 13px;
        display: block;
        color: $gray-light;

        &.danger {
            color: $danger-light  !important;
        }

        &.warning {
            color: $warning-dark  !important;
        }
    }


}

.input-form-clear {
    width: 22px;
    height: 22px;
    background-color: rgba($gray, .1);
    border-radius: 11px;
    transform: rotate(45deg);
    transition: all 140ms ease-in-out;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($gray, .5);
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 4px 11px rgba(0, 0, 0, .15);
    }

    &.danger {
        color: $danger;
    }
}

.select-box-list-container {
    position: relative;
    padding: 0 !important;
    min-width: 200px;

    .select-box {
        position: relative;

        .input-field-chevron {
            position: absolute;
            z-index: 999;
            top: 0;
            bottom: 0;
            right: 0;
            width: 36px;
            height: 35px;
            font-size: 15px;
            line-height: -5px;
            text-align: center;
            padding: 3px;
            user-select: none;
            cursor: pointer;

            &::before {
                content: '\0000a0';
                position: absolute;
                width: 1px;
                height: 20px;
                background: #cccccc;
                z-index: 999;
                right: 37px;
                margin: 5px 0 0 0;
            }

            &.disabled {
                cursor: not-allowed;
            }
        }

        .selected-item {
            //display: flex;
            position: absolute;
            align-items: center;
            top: 0;
            left: 10px;
            right: 40px;
            height: 100%;
            padding-right: 16px;
            user-select: none;

            .selected-item-info {
                display: flex;
                flex-grow: 1;
                margin: 8px 0;

                .selected-item-name {
                    flex-grow: 1;
                    margin-right: 7px;
                }

                span {
                    font-size: 11px;
                    font-weight: 500;
                    background-color: #d7d7d7;
                    padding: 3px 9px;
                    border-radius: 5px;
                    margin-right: 5px;

                    &.current {
                        background: #2684FF;
                        ;
                        color: white;
                    }

                    &.finalized {
                        background: rgba(234, 84, 85, .1);
                        color: #ea5455;
                    }
                }
            }

            svg {
                width: 15px;
                height: 15px;
                color: #cccccc;
                margin-top: -4px;
            }

            .remove-selected {
                position: absolute;
                right: 2px;
                top: 8px;
            }
        }

        .input-form-clear {
            &:disabled {
                cursor: not-allowed;
            }

            top: calc(50% - 11px);
            right: 45px;
        }

    }

    .select-box-list-body {
        top: 35px;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .1);
        margin-bottom: 8px;
        margin-top: 8px;
        position: absolute;
        width: 100%;
        min-width: 330px;
        z-index: 9999;
        box-sizing: border-box;
        padding: 4px 0;
        overflow: hidden;
        //visibility: hidden;
        //opacity: 0;

        &.show {
            visibility: visible;
            opacity: 1;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            transition: all 140ms ease-in-out;
            max-height: 250px;
            overflow: auto;

            &.modal-opened {
                min-height: 250px;
            }

            &.delete-modal-opened {
                min-height: 150px;
            }

            li {
                color: inherit;
                cursor: default;
                font-size: inherit;
                padding: 3px 12px;
                width: 100%;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                flex-grow: 1;

                &:nth-child(odd) {
                    background-color: rgba(100, 100, 100, .1);
                }

                &:hover {
                    background-color: $primary-pastel;
                }

                .title {
                    flex-grow: 1;
                    padding: 11px 0;
                    cursor: pointer;
                }

                .edit {
                    padding: 0 4px;
                    background: rgba(38, 132, 255, .2);
                    border-radius: 5px;
                    margin-right: 10px;

                    svg {
                        color: #2684FF !important;
                        cursor: pointer;
                        width: 15px;
                    }
                }

                .delete {
                    padding: 0 4px;
                    background: rgba(234, 84, 85, .1);
                    border-radius: 5px;

                    svg {
                        color: #ea5455;
                        cursor: pointer;
                        width: 15px;
                    }
                }

                .infos {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        font-size: 11px;
                        font-weight: 500;
                        background-color: #d7d7d7;
                        padding: 3px 9px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }

                }

                &.current {
                    background: #DEEBFF;
                    color: #2684FF;

                    .infos {
                        span {
                            background: #2684FF;
                            color: white;
                        }
                    }

                    &:hover {
                        background: rgba(38, 132, 255, .2);
                        ;
                    }
                }

                &.finalized {

                    .infos {
                        span {
                            background: rgb(255, 223, 223);
                            color: #ea5455;
                        }
                    }

                    &:hover {
                        //background: rgba(53, 45, 151, .3);
                        background: #DEEBFF
                    }
                }

                &.option-primary {
                    background-color: $primary-pastel;
                    color: $primary;

                    &.selected {
                        background: $primary;
                        color: $white;

                        .infos {
                            span {
                                background: $primary;
                                color: $white;
                            }
                        }
                    }
                }

                &.option-secondary {
                    background-color: $secondary-pastel;
                    color: $secondary;

                    &.selected {
                        background: $secondary;
                        color: $white;

                        .infos {
                            span {
                                background: $secondary;
                                color: $white;
                            }
                        }
                    }

                }

                &.option-success {
                    background-color: $success-pastel;
                    color: $success;

                    &.selected {
                        background: $success;
                        color: $white;

                        .infos {
                            span {
                                background: $success;
                                color: $white;
                            }
                        }
                    }
                }

                &.option-info {
                    background-color: $info-pastel;
                    color: $info;

                    &.selected {
                        background: $info;
                        color: $white;

                        .infos {
                            span {
                                background: $info;
                                color: $white;
                            }
                        }
                    }
                }

                &.option-system {
                    background-color: $system-pastel;
                    color: $system;

                    &.selected {
                        background: $system;
                        color: $white;

                        .infos {
                            span {
                                background: $system;
                                color: $white;
                            }
                        }
                    }
                }

                &.option-warning {
                    background-color: $warning-pastel;
                    color: $warning;

                    &.selected {
                        background: $warning;
                        color: $white;

                        .infos {
                            span {
                                background: $warning;
                                color: $white;
                            }
                        }
                    }
                }

                &.option-danger {
                    background-color: $danger-pastel;
                    color: $danger;

                    &.selected {
                        background: $danger;
                        color: $white;

                        .infos {
                            span {
                                background: $danger;
                                color: $white;
                            }
                        }
                    }
                }

                &.option-spanish-gray {
                    background-color: $spanish-gray-pastel;
                    color: $spanish-gray;

                    &.selected {
                        background: $spanish-gray;
                        color: $white;

                        .infos {
                            span {
                                background: $spanish-gray;
                                color: $white;
                            }
                        }
                    }
                }


                &.no-data {
                    height: 150px;
                    text-align: center;
                    background: white !important;

                    h3 {
                        font-size: 16px;
                        color: #777;
                    }

                    p {
                        font-size: 14px;
                        margin: 0;
                    }
                }

                &.selected {
                    background: $primary;
                    color: $white;

                    .title {
                        color: white;
                    }

                    svg {
                        color: white;
                    }

                    .edit,
                    .delete {
                        background: rgba(255, 255, 255, .1);

                        svg {
                            color: white !important;
                        }
                    }

                    &:hover {
                        background: $primary-dark;
                    }
                }
            }
        }
    }

    .select-box-list-footer {
        padding: 8px 12px;
        border-top: solid 1px rgba(0, 0, 0, .1);
        cursor: pointer;

        &:hover {
            background-color: rgba(100, 100, 100, .1);
        }
    }

    .overlay {
        background: rgba(0, 0, 0, .1);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 4px;
        visibility: hidden;
        opacity: 1;
        transition: all 140ms ease-in-out;

        &.show {
            visibility: visible;
            opacity: 1;
        }
    }

    .search-modal {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .1);
        border-radius: 4px;
        position: absolute;
        background: white;
        bottom: -100%;
        width: 100%;
        transition: all 140ms ease-in-out;
        visibility: hidden;
        opacity: 1;

        &.show {
            bottom: 0;
            visibility: visible;
            opacity: 1;
        }

        .search-modal-body {
            h3 {
                font-size: 16px;
                padding: 7px;
                text-align: center;
            }

            padding: 15px;
        }

        .search-modal-footer {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            border-top: solid 1px rgba(0, 0, 0, .1);

            button {
                padding: 7px 10px;
                width: 120px;
            }
        }
    }

    .select-datetime {
        position: absolute;
        z-index: 9999;
        background: rgba(0, 0, 0, .1);
        width: 100%;
        bottom: 0;
        top: 0;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        transition: all 140ms ease-in-out;
        transform: scale(.7);

        &.show {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }

    &.disabled {
        .select-box>.input-field-chevron {
            cursor: not-allowed;
        }

        .select-box-list-body {
            visibility: hidden;
            opacity: 0;
        }
    }

}

.gg-eye {
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 24px;
    height: 18px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    overflow: hidden;
    box-sizing: border-box;
    transform: scale(.7);
   }
   
   .gg-eye::after,
   .gg-eye::before {
    content: "";
    display: block;
    border-radius: 100px;
    position: absolute;
    box-sizing: border-box
   }
   
   .gg-eye::after {
    top: 2px;
    box-shadow:
    inset 0 -8px 0 2px,
    inset 0 0 0 2px;
    width: 24px;
    height: 24px
   }
   
   .gg-eye::before {
    width: 8px;
    height: 8px;

    border: 4px solid;
    bottom: 4px;
    left: 8px
   } 