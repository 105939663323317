// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.ui-droppeable-container {
    border: dotted 1px $border-color;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    z-index: 0;
    overflow-x: auto;
    min-height: 120px;
    height: 100%;
    .ui-droppeable-area {
      width: 100%;
      height: 100%;
      background: $white;
      border-radius: 3px;
      padding: 15px;
      border: solid 1px $border-color-light;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .ui-droppeable-content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .ui-droppeable-info {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          h3 {
            margin: 0;
            color: $gray-light;
            font-size: 18px;
            font-weight: 400;
          }
        }
      }
      .ui-droppeable-file-formats {
        font-size: 12px;
        color: $gray-light;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: inline-flex;
          li {
            background: $primary-pastel;
            display: block;
            padding: 1px 5px;
            border-radius: 3px;
            color: $primary;
            margin: 0 3px;
            font-size: 12px;
          }
        }
      }
    }      
  }