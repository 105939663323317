// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Load variable overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

.app-header-ui {
    background: $white;
    height: 50px;
    width: 100%;
    position: absolute;
    z-index: 999;
    top: 0;
    box-shadow: $box-shadow;
    display: flex;
    align-items: center;
    &.impersonate {
      //border-top: solid 4px $primary;
    }
    .close-impersonate {
      border-left: solid 1px $border-color;
      height: 50px;
      margin-left: 7px;
      background: $primary-pastel;
      color: $primary;
      padding: 7px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -10px;
      cursor: pointer;
    }
    .app-header-logo {
        margin-left: 10px;
    }
    .app-header-description {
        font-size: 18px;
        color: $gray-light;
        padding-left: 10px;
        margin-left: 10px;
        border-left: solid 1px $border-color;
        @media (max-width: 567px){
          font-size: 12px;
        }
    }
    .app-header-toggle-menu {
        width: 30px;
        height: 30px;
        position: relative;
        transition: $transition-fast;
        z-index: 9999;
        background-color: $gray-pastel;
        color: $gray-light;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .5rem;
        cursor: pointer;
        &:hover {
            border-color: $primary;
            transform: scale(1.1);
            &::before ,  &::after {
                border-color: $primary;
            }
        }
        &.menu-is-open {
            opacity: 0;
        }
    }
    .app-master-menu-close {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $danger-dark;
        color: $white;
        z-index: 9999;
        left: calc(50% - 25px);
        bottom: 10px;
        border-radius: $border-radius;
        font-size: 13px;
        transition: $transition-fast;
        cursor: pointer;
        &:hover {
            //background-color: $danger;
            transform: scale(1.2);
        }
    }
    .user-nav {
        .user-name {
            font-size: 14px;
            font-weight: 500;
            color: $primary;
            line-height: 13px;
        }
        .user-section {
            font-size: 1.125rem;
            font-weight: 700;
            color: rgb(75 85 99);
        }

    }

    .app-user-search {
        flex-grow: 1;
        margin: 0 40px 0 10px;
        .search-bar-form {
            max-width: 600px;
        }
    }
    .user-dropdown-menu {
        position: absolute;
        top: calc(100% - 30px);
        width: 220px;
        right: 7px;
        background: $white;
        box-shadow: $box-shadow;
        border-radius: 3px;
        //border: solid 1px $border-color;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: $transition-fast;
        &.show {
          visibility: visible;
          opacity: 1;
          top: calc(100% + 8px);
        }
        .user-dropdown-menu-options {
          padding: 10px;
          max-height: calc( 100vh - 120px);
          overflow-x: auto;
          .user-dropdown-menu-item {
            background: $gray-pastel;
            color: $gray-light;
            padding: 10px;
            margin: 0 0 5px 0;
            border-radius: 3px;
            line-height: 16px;
            cursor: pointer;
            b {
              font-weight: 500;
              margin-bottom: 4px;
            }
            &.selected {
              background: $primary-pastel;
              color: $primary-dark;
            }
            &:last-child {
              margin: 0;
            }

            &.disabled {
              opacity: .6;
              cursor: not-allowed;
            }
          }
        }
        .user-dropdown-menu-footer {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-top: solid 1px $border-color;
          background-color: $gray-pastel;
          .user-dropdown-menu-footer-item {
            display: flex;
            align-items: center;
            padding: 5px;
            transition: $transition-fast;
            border-radius: 3px;
            cursor: pointer;
            svg {
              margin-right: 5px;
            }
            &.changepass {
              color: $primary-dark;
            }
            &.logout {
              color: $danger;
            }
            &:hover {
              &.changepass {
                background: $primary-pastel;
              }
              &.logout {
                background: $danger-pastel;
              }
            }
          }
        }
        table {
          margin: 0;
          tr {
            transition: $transition-fast;
            &:hover {
              td {
                background: $primary-pastel;
                color: $primary-dark;
                cursor: pointer;
              }
            }

            &.selected {
              td {
                background: $primary-dark;
                color: $white;
                cursor: pointer;
              }
            }
          }
        }
        ul {
          padding: 0;
          margin: 0;
        }
      }
    .user-dropdown-menu-center {
        position: absolute;
        top: calc(100% - 30px);
        width: 220px;
        right: auto;
        background: $white;
        box-shadow: $box-shadow;
        border-radius: 3px;
        //border: solid 1px $border-color;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: $transition-fast;
        &.show {
          visibility: visible;
          opacity: 1;
          top: calc(100% + 8px);
        }
        .user-dropdown-menu-options {
          padding: 10px;
          max-height: calc( 100vh - 120px);
          overflow-x: auto;
          .user-dropdown-menu-item {
            background: $gray-pastel;
            color: $gray-light;
            padding: 10px;
            margin: 0 0 5px 0;
            border-radius: 3px;
            line-height: 16px;
            cursor: pointer;
            b {
              font-weight: 500;
              margin-bottom: 4px;
            }
            &.selected {
              background: $primary-pastel;
              color: $primary-dark;
            }
            &:last-child {
              margin: 0;
            }

            &.disabled {
              opacity: .6;
              cursor: not-allowed;
            }
          }
        }
        .user-dropdown-menu-footer {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-top: solid 1px $border-color;
          background-color: $gray-pastel;
          .user-dropdown-menu-footer-item {
            display: flex;
            align-items: center;
            padding: 5px;
            transition: $transition-fast;
            border-radius: 3px;
            cursor: pointer;
            svg {
              margin-right: 5px;
            }
            &.changepass {
              color: $primary-dark;
            }
            &.logout {
              color: $danger;
            }
            &:hover {
              &.changepass {
                background: $primary-pastel;
              }
              &.logout {
                background: $danger-pastel;
              }
            }
          }
        }
        table {
          margin: 0;
          tr {
            transition: $transition-fast;
            &:hover {
              td {
                background: $primary-pastel;
                color: $primary-dark;
                cursor: pointer;
              }
            }

            &.selected {
              td {
                background: $primary-dark;
                color: $white;
                cursor: pointer;
              }
            }
          }
        }
        ul {
          padding: 0;
          margin: 0;
        }
      }
}
