
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../assets/scss/core/variables/components-variables";

.rc-tools-ui {
    box-shadow: $box-shadow;
    z-index: 99999;
    background: $white;
    height: 100%;
    position: relative;
    .rc-tools-ui-seach {
        position: absolute;
        width: 1095px;
        height: 50px;
        background-color: #000;
        right: 100%;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: solid 1px $border-color-light;
            transition: $transition-fast;
            cursor: pointer;
            position: relative;
            &:last-child {
                border: none;
            }
            svg {
                width: 22px;
                height: 22px;
                path {
                    fill: $gray-light;
                }
            }
            &.active {
                background: $primary-pastel;
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
            &.disabled {
                cursor: not-allowed;
                svg {
                    opacity: .4;
                }
            }
        }
    }
}