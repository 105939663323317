@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";

.filter-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    &__title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
            flex-grow: 1;
        }
    }

    &__icon {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button {
        border: 1px solid $border-color;
        border-radius: 3px;
        background-color: $gray-pastel;
        height: 26px;
        width: 26px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            border-color: #35b2d94d;
            background-color: #D9EEF5;

            svg {
                stroke: #35B2D9
            }
        }
    }

    &__dropdown {
        width: auto;
        min-width: 100px;
        max-height: 190px;
        overflow-x: auto;
        border: 1px solid $border-color;
        background-color: #fff;
        position: absolute;
        margin-top: 30px;
        border-radius: 5px;
        transition: 100ms;
        &::-webkit-scrollbar {
            height: 10px;
            width: 5px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: $gray-100;
            border-radius: 3px;
        }
    
        &::-webkit-scrollbar-track {
            background: $gray-50;
            border-radius: 50px;
        }
        &.hide {
            transform: translateY(-40px);
            visibility: hidden;
            opacity: 0;
        }

        &.show {
            transform: translateY(0px);
            visibility: visible;
            opacity: 1;
        }

    }

    &__item {
        padding: 3px 12px;
        font-weight: 400;
        cursor: pointer;


        &:nth-child(odd) {
            background-color: rgba(100, 100, 100, .1);
        }

        &.selected {
            background: $primary;
            color: $white;

            &:hover {
                background: $primary;
                color: $white;
            }
        }

        &:hover {
            background: $primary-pastel;
            color: $primary;
        }
    }
}