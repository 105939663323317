@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";


button.button-ui {
    background: transparent;
    border: none;
    padding: 9px 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.4s;
    position: relative;
    font-size: 13px;
    height: 35px;
    white-space: nowrap;
    transform: translate(0);
    &:hover {
        background: #EFEFEF;
        .rc-tooltip-ui{
            opacity: 1;
            visibility: visible;
            margin-right: 10px;
        }
    }

    &.md {
        padding: 18px;
    }
    &.sm {
        height: 30px;
        padding: 5px 12px;
        svg {
            height: 16px;
        }
    }
    &.xs {
        height: 25px;
        padding: 2px 7px;
        font-size: 12px;
        svg {
            height: 15px;
        }
    }
    .spinner-border-sm {
        position: absolute;
    }
    span.processing {
        opacity: 0;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 3px;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s;
        box-shadow: 0 0 10px 40px rgb(212, 212, 212);
    }
    &:active:after {
        box-shadow: 0 0 0 0 rgb(205, 205, 205);
        position: absolute;
        border-radius: 5px;
        left: 0;
        top:0;
        opacity: 1;
        transition: 0s;
    }
      &:active {
        top: 1px;
      }
    .button-icon {
        margin-right: 8px;
    }
    &.add {
        color: $success;
        &:active {
            background: #E9F8F0;
        }
    }
    &.save {
        color: $system;
        &:active {
            background: $system-pastel;
        }
    }
    &.confirm {
        color: $warning;
        &:active {
            background: $warning-pastel;
        }
    }
    &.undo {
        color: $info;
        &:active {
            background: $info-pastel;
        }
    }
    &.delete {
        color: $danger;
        &:active {
            background: $danger-pastel;
        }
    }
    &.cancel {
        color: $spanish-gray;
        &:active {
            background: $spanish-gray-pastel;
        }
    }
    &.edit {
        color: $primary;
        &:active {
            background: $primary-pastel;
        }
    }

    &.schedule {
      color: $white;
      background-color: $dark-chat-bg-color;
      border-radius: 40px;
      &:active {
          background: $primary-pastel;
      }
  }
    &.exclude {
        color: $danger;
        &:active {
            background: $danger-pastel;
        }
    }
    &.button-gray {
        color: $spanish-gray-pastel;
        &:active {
            background: $spanish-gray;
        }
    }
    &.button-primary {
        color: $sky-blue;
        &:active {
            background: $sky-blue-pastel;
        }
    }
    &.button-secondary {
        color: $water-green;
        &:active {
            background: $water-green-pastel;
        }
    }
    &.button-default {
        color: $gray;
        &:active {
            background: $gray-pastel;
        }
    }
    &.button-success {
        color: $success;
        &:active {
            background: $success-pastel;
        }
    }
    &.button-system {
        color: $system;
        &:active {
            background: $system-pastel;
        }
    }
    &.button-info {
        color: $info;
        &:active {
            background: $info-pastel;
        }
    }
    &.button-warning {
        color: $warning;
        &:active {
            background: $warning-pastel;
        }
    }
    &.button-danger {
        color: $danger;
        &:active {
            background: $danger-pastel;
        }
    }
    &.button-spanish-gray {
        color: $spanish-gray;
        &:active {
            background: $spanish-gray-pastel;
        }
    }
    &.add.dark-button {
        color: #FFF;
        background: $success;
    }
    &.save.dark-button {
        color: #FFF !important;
        background: $system;
    }
    &.confirm.dark-button {
        color: #FFF !important;
        background: $warning;
    }
    &.undo.dark-button {
        color: #FFF !important;
        background: $info;
    }
    &.delete.dark-button {
        color: #FFF !important;
        background: $danger;
    }
    &.cancel.dark-button {
        color: #FFF !important;
        background: $spanish-gray;
    }
    &.edit.dark-button {
        color: #FFF !important;
        background: $primary;
    }
    &.exclude.dark-button {
        color: #FFF !important;
        background: $danger;
    }
    &.button-primary.dark-button {
        color: #FFF;
        background: $primary;
    }
    &.button-secondary.dark-button {
        color: #FFF !important;
        background: $secondary;
    }
    &.button-default.dark-button {
        color: #FFF !important;
        background: $gray;
    }
    &.button-success.dark-button {
        color: #FFF !important;
        background: $success;
    }
    &.button-system.dark-button {
        color: #FFF !important;
        background: $system;
    }
    &.button-info.dark-button {
        color: #FFF !important;
        background: $info;
    }
    &.button-warning.dark-button {
        color: #FFF !important;
        background: $warning;
    }
    &.button-danger.dark-button {
        color: #FFF !important;
        background: $danger;
    }
    &.button-spanish-gray.dark-button {
        color: #FFF !important;
        background: $spanish-gray;
    }
    &:disabled {
        background: rgba($gray-light, 0.6) !important;
        cursor: not-allowed;
        &:after {
            box-shadow: 0 0 10px 40px rgba(212, 212, 212, 0);
        }
        &:active:after {
            box-shadow: 0 0 0 0 rgba(205, 205, 205, 0);
        }
        &:active {
        top: 0;
        }
        svg {
            color: #FFF;
        }
    }
    .gray {
        color: #A3ABB2 !important;
    }
}
