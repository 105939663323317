@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Load variable overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../assets/scss/core/variables/components-variables";

.disabled {
  cursor: not-allowed !important;
}
.InputLabelWithAssentRoot {
  display: flex;
  align-items: center;
}
.InputLabelAssent {
  font-size: 12px;
  color: #fff !important;
  background: $primary;
  padding: 2px;
  width: 2em !important;
  height: 2em !important;
  border-radius: 50%;
  font-weight: bold;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-time {
  .ui-select-box {
    &__input {
      min-width: 150px;
    }
  }
}

.ui-select-box {
  &__container {
    position: relative;
  }

  input {
    &:focus {
      //display: none;
    }
  }

  &__input {
    .input-actions {
      right: 40px;
    }

    &:focus {
      display: none;
    }
  }

  &__selected-item {
    position: absolute;
    align-items: center;
    top: 0;
    left: 10px;
    right: 40px;
    height: 100%;
    padding-right: 16px;
    user-select: none;
    cursor: pointer;

    .selected-item-info {
      display: flex;
      flex-grow: 1;
      margin: 8px 0;

      &.sm {
        margin-top: 5px;
        margin-bottom: 4px;
      }

      .selected-item-name {
        flex-grow: 1;
        margin-right: 7px;
        font-size: 14px;
      }
    }

    .remove-selected {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 2px;
      top: 8px;

      &.sm {
        width: 16px;
        height: 16px;

        .input-form-clear {
          height: 100%;
          width: 100%;
          font-size: 18px;
        }
      }
    }
  }

  &__chevron {
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 35px;
    font-size: 15px;
    line-height: -5px;
    text-align: center;
    padding: 3px;
    user-select: none;
    cursor: pointer;

    svg {
      width: 13px;
    }

    &.sm {
      height: 30px;
      line-height: 1;

      svg {
        margin-top: 3px;
      }

      &::before {
        height: 15px;
      }
    }

    &::before {
      content: "\0000a0";
      position: absolute;
      width: 1px;
      height: 20px;
      background: #cccccc;
      z-index: 999;
      right: 35px;
      margin: 5px 0 0 0;
    }
  }

  &__collapse-container {
    display: flex;
    flex-direction: column;
  }

  .ui-select-box-dropdown {
    position: fixed;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    z-index: 9999;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    //transition: 140ms;
    z-index: 999999;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    &__container {
      list-style: none;
      margin: 0;
      padding: 0;
      transition: all 140ms ease-in-out;
    }

    &__item {
      color: inherit;
      font-size: inherit;
      width: 100%;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: pointer;

      input {
        &[type="checkbox"] {
          margin-right: 12px;
        }
      }

      &:nth-child(odd) {
        background-color: rgba(100, 100, 100, 0.1);
      }

      // &:hover {
      //     background-color: $primary-pastel;
      // }

      &.selected {
        background: $primary;
        color: $white;
      }
    }

    &__title {
      font-weight: 900;
      padding: 3px 5px;
    }

    &__action {
      padding: 3px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background: $primary-pastel;
        color: $primary;
      }
    }

    &__collapse {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px rgba($gray-light, 0.3);
      background: $gray-pastel;
      color: rgba($gray-light, 0.6);
      border-radius: 2px;
      cursor: pointer;
      z-index: 1;
    }

    .collapse-list {
      background-color: $white;
      padding-left: 31px;

      .ui-select-box-dropdown__item {
        &:nth-child(1) {
          .ui-select-box-dropdown__action {
            &::after {
              top: -4px;
              height: calc(50% + 4px);
            }
          }
        }
      }

      .ui-select-box-dropdown__action {
        position: relative;
        background-color: $white;

        &:hover {
          background: $primary-pastel;
          color: $primary;
        }

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          background-color: $border-color;
          left: -12px;
          top: -50%;
        }

        &::before {
          content: "";
          position: absolute;
          height: 1px;
          width: 24px;
          background-color: $border-color;
          left: -12px;
          top: 50%;
        }
      }
    }
  }
}
