// ===============================================================================================
//  File Name: knowledge-base.scss
//  Description: Knowledge Base Page Content SCSS
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy React Admin Template
//  Version: 1.0
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";


.body-card-container {
    background: $white;
    padding: 20px;
    border: solid 1px rgba($gray-100,.8);
    display: flex;
    flex-direction: column;
    //width: 450px;
    overflow: hidden;
    max-height: calc(100vh - 60px);
    max-width: 100%;
    //height: auto;
    border-radius: 3px;
    position: relative;
    transition: $transition-fast;
    .body-card-header {
        //border-bottom: solid 1px rgba($gray-100,.8);
        padding: 20px;
        margin: -10px -20px -10px -20px;
        border-radius: 3px 3px 0 0;
        h1 {
            font-size: 17px;
            color: $gray
        }
    }
    .body-card-content {
        padding: 20px;
        margin: 0 -20px;
        min-height: 0px;
        flex: 1 1 auto;
        overflow-y: auto;    
        position: relative;
        &.scroll {
            flex: 1 1 auto;
            overflow-y: auto;    
        }
    }
    .body-card-footer {
        border-top: solid 1px rgba($gray-100,.8);
        padding: 8px 20px;
        margin: 0 -20px -20px -20px;
        background-color: $gray-pastel;
        border-radius: 0 0 3px 3px;
        transition: $transition-fast;
    }
    .body-card-message {
        position: absolute;
        z-index: 3;
        top: -25px;
        left: 0px;
        right: 0px;
        padding: 10px;
        border-radius: 3px 3px 0 0;
        transition: $transition-fast;
        visibility: hidden;
        opacity: 0;
        display: flex;
        justify-content: center;
        &.show {
            top: 0;
            visibility: visible;
            opacity: 1;
        }
        svg {
            width: 18px;
            margin-right: 7px;
        }
        .body-card-close-button {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($white,.4);
            border-radius: 3px;
            transition: $transition-fast;
            svg {
                width: 13px;
                margin-right: 0;
                color: $white;
            }
            &:hover {
                transform: scale(1.1);
                box-shadow: $box-shadow-basic;
            }
        }
    }
    &.success {
        border: solid 1px rgba($success-dark,.2);
        .body-card-footer {
            background-color: $success-pastel;
            border-color: rgba($success-dark, .2);
        }
        .body-card-header {
            position: relative;
            h1 {
                position: relative;
                z-index: 2;
            }
            &:before {
                content: '';
                position: absolute;
                height: 200%;
                width: 100%;
                left: 0;
                top: -10px;
                background: linear-gradient(180deg, rgba($success-pastel,.8) 50%, rgba($success-pastel,0) 100%);
                z-index: 0;
                
    
            }
        }
        .body-card-content, .body-card-content p {
           color: $spanish-gray-dark;
        }
        h1 {
            color: $success-dark;
        }
        .body-card-message {
            color: $white !important;
            background: $success;
            border-bottom: solid 1px rgba($success-dark, .2);
        }
        input {
            border-color: rgba($success-dark, .2);
        }
        label, input:focus ~ label.animated {
            color: $success-light;
        }
    }   

    &.warning {
        border: solid 1px rgba($warning-dark,.2);
        .body-card-footer {
            background-color: $warning-pastel;
            border-color: rgba($warning-dark, .2);
        }
        .body-card-header {
            position: relative;
            h1 {
                position: relative;
                z-index: 2;
            }
            &:before {
                content: '';
                position: absolute;
                height: 200%;
                width: 100%;
                left: 0;
                top: -10px;
                background: linear-gradient(180deg, rgba($warning-pastel,.8) 50%, rgba($warning-pastel,0) 100%);
                z-index: 0;
                
    
            }
        }
        .body-card-content, .body-card-content p {
           color: $spanish-gray-dark;
        }
        h1 {
            color: $warning-dark;
        }
        .body-card-message {
            color: $white !important;
            background: $warning;
            border-bottom: solid 1px rgba($warning-dark, .2);
        }
        input {
            border-color: rgba($warning-dark, .2);
        }
        label, input:focus ~ label.animated {
            color: $warning-light;
        }
    }   

    &.danger {
        border: solid 1px rgba($danger-dark,.2);
        .body-card-footer {
            background-color: $danger-pastel;
            border-color: rgba($danger-dark, .2);
        }
        .body-card-header {
            position: relative;
            h1 {
                position: relative;
                z-index: 2;
            }
            &:before {
                content: '';
                position: absolute;
                height: 200%;
                width: 100%;
                left: 0;
                top: -10px;
                //background: linear-gradient(180deg, rgba($danger-pastel,.8) 50%, rgba($danger-pastel,0) 100%);
                z-index: 0;
                
    
            }
        }
        .body-card-content, .body-card-content p {
           color: $spanish-gray-dark;
        }
        h1 {
            color: $danger-dark;
        }
        .body-card-message {
            color: $white !important;
            background: $danger;
            border-bottom: solid 1px rgba($danger-dark, .2);
        }
        input {
            border-color: rgba($danger-dark, .2);
        }
        label, input:focus ~ label.animated {
            color: $danger-light;
        }
    }   
}