@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../../assets/scss/bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../assets/scss/core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

@import "../../assets/scss/core/variables/components-variables";

.ui-dropdown {
    position: relative;
    align-items: flex-start;
    justify-content: flex-end;
    &__label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
    }

    &__items {
        position: fixed;
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .1);
        margin-top: 5px;
        z-index: 9999;
        box-sizing: border-box;
        padding: 4px 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        //transition: 140ms;
        z-index: 999999;

        &.show {
            visibility: visible;
            opacity: 1;
        }

    }

    
}